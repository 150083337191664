import { Button } from "antd";
import React, {
    forwardRef,
    Fragment,
    useEffect,
    useRef,
    useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting, { getProductSetting } from "../../api/getSettings";
import "./style.css";
import _ from "lodash"
import { DECIMAL_NUMBER, getMomentObj } from "../../utils/functions";
import converter from "number-to-words";

// import { saveAs } from "file-saver";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {

    const word = converter.toWords(data?.totalAmount || 0);

    const totalInWords = word.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());

    // const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
    // const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);

    const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

    const getProductGst = (product) => {
        return _.get(product, "productGST") || _.get(product, "vatPrice") || 0;
    }

    return (
        <Fragment>
            <div ref={ref} className="border p-5">
                <div className="">
                    <div className="mx-auto px-5 py-2 ">
                        <div className="text-center text-xl mb-1 font-bold">{invoiceData?.companyName}</div>
                        {invoiceData?.gst_no && <div className="text-center text-base font-semibold mb-1">{invoiceData?.gst_no}</div>}
                        {invoiceData?.address && <div className="text-center text-base mb-1">{invoiceData?.address} {invoiceData?.address_l2 && invoiceData?.address_l2}</div>}
                        {invoiceData?.phone && <div className="text-center text-base mb-1">Phone No: {invoiceData?.phone}</div>}
                        {invoiceData?.email && <div className="text-center text-sm mb-1">{invoiceData?.email}</div>}
                    </div>

                    <table className="w-full border border-[#abaaaa] mb-5 " style={{ minHeight: 900 }} >
                        <thead className="border-b border-[#abaaaa]">
                            <tr>
                                <th className="border-r border-b border-[#abaaaa] text-left pl-4" rowspan="2" colSpan={2} >
                                    <h4 className="text-xl">{data?.customer?.name}</h4>
                                    <h4 className="text-xl font-medium">{data?.customer?.address}</h4>
                                </th>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" colSpan={3}>
                                    <h4 className="text-lg">Invoice No: {data?.manualId}</h4>
                                </th>
                            </tr>
                            <tr>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2 w-20" colSpan={3}>
                                    <h4 className="text-lg">Invoice Date: {getMomentObj(data?.date).format("YYYY-MM-DD")}</h4>
                                </th>
                            </tr>
                            <tr>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" >S.No</th>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" >Variety's</th>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" >Qty</th>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" >Rate</th>
                                <th className="border-r border-b border-[#abaaaa] text-left p-2" >Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                _.map(_.get(data, "saleInvoiceProduct", []), (product, index) => (
                                    <tr className={`${index + 1 < _.size(data.saleInvoiceProduct) ? "h-8" : ""}`}>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-2" >{index + 1}</td>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-2" >{product?.product?.name}</td>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-2" >{product?.productQuantity}</td>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-2" >{product?.productSalePrice}</td>
                                        <td className="border-r border-[#abaaaa] align-top text-left p-2" >{product?.productQuantity * product?.productSalePrice}</td>
                                    </tr>
                                ))
                            }
                            <tr className="h-16">
                                <td className="border-r border-b border-t border-[#abaaaa] text-left pl-4" rowspan="2" colSpan={2} >
                                    <div className="-mt-12">
                                        <h4 className="text-lg font-bold">Amount in words:</h4>
                                        <h4 className="text-lg font-medium">{totalInWords}</h4>
                                    </div>
                                </td>
                                <td className="border-r border-b border-t border-[#abaaaa] text-left p-2" colSpan={3}>
                                    <div className="flex text-right -mt-3 mb-4">
                                        <h4 className="text-base w-2/4 font-semibold">Sub Total: </h4>
                                        <h4 className="text-base w-2/4 pr-4">{DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>
                                </td>
                            </tr>
                            <tr className="h-16">
                                <td className="border-r border-b border-[#abaaaa] text-left p-2" colSpan={3}>
                                    <div className="flex text-right -mt-4 mb-2">
                                        <h4 className="text-base w-2/4">Previous Balance:</h4>
                                        <h4 className="text-base w-2/4 pr-4"> 0</h4>
                                    </div>
                                    <div className="flex text-right -mb-3">
                                        <h4 className="text-base w-2/4">Current Bill: </h4>
                                        <h4 className="text-base w-2/4 pr-4">{DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>
                                </td>
                            </tr>
                            <tr className="h-20">
                                <td className="border-r border-b border-[#abaaaa] text-left pl-4" colSpan={2} >
                                </td>
                                <td className="border-r border-b border-[#abaaaa] text-left p-2" colSpan={3}>
                                    <div className="flex text-right -mt-4">
                                        <h4 className="text-base w-2/4">Total Amount:</h4>
                                        <h4 className="text-base w-2/4 pr-4"> {DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>
                                    <div className="flex text-right">
                                        <h4 className="text-base w-2/4">Amount Received:</h4>
                                        <h4 className="text-base w-2/4 pr-4"> {DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>
                                    <div className="flex text-right -mb-3">
                                        <h4 className="text-base w-2/4">Balance Amount: </h4>
                                        <h4 className="text-base w-2/4 pr-4">{DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</h4>
                                    </div>
                                </td>
                            </tr>
                            <tr className="h-5">
                                <td className="border-r border-b border-[#abaaaa] text-left pl-4 text-base font-semibold" colSpan={2} >
                                    <h4 className="-mt-2 mb-14">Received and Accepted</h4>
                                    <h4 className="-mb-1">Signature & Stamp</h4>
                                </td>
                                <td className="border-r border-[#abaaaa] text-left p-2 text-base font-semibold text-right" colSpan={3}>
                                    <h4 className="-mt-2">For <span className="text-lg">{invoiceData?.companyName}</span></h4>
                                    <div className="flex justify-end h-18">{invoiceData?.signatureSnapshot && <img className="h-[70px]" src={invoiceData?.signatureSnapshot} />}</div>
                                    <h4 className="-mb-1">Authorised Signatory</h4>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>

            </div>
        </Fragment>
    );
});

const SaleInvoiceV1 = ({ data, vatAmount, styles = {}, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => successCallBack?.(),
    });

    const [invoiceData, setInvoiceData] = useState(null);
    const [productSettings, setProductSettings] = useState(null);
    useEffect(() => {
        getSetting().then((data) => setInvoiceData(data.result));
        getProductSetting().then((data) => setProductSettings(data.result));
    }, []);

    return (
        <div>
            {/* <div className='' style={{ position: 'absolute', left: 100, right: 0, top: 1000, background: 'white' }}> */}
            <div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }}>
                <PrintToPdf
                    ref={componentRef}
                    data={data}
                    invoiceData={invoiceData}
                    vatAmount={vatAmount}
                    isQuotation={isQuotation}
                    productSettings={productSettings}
                />
            </div>
            {invoiceData && (
                <>
                    <Button style={styles} type='primary' shape='round' onClick={() => {
                        if (_.isFunction(preCall)) {
                            preCall(handlePrint);
                        } else {
                            handlePrint()
                        }
                    }}>
                        A4 Print3
                    </Button>
                </>
            )}
        </div>
    );
};

export default SaleInvoiceV1;
