// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddProdBrand_importCsvCard__YAA3D {
  height: 100%;
}

.AddProdBrand_addProdBrandBtnContainer__G6k8Y {
  display: flex;
  justify-content: center;
  margin-top: 24px !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/productBrand/AddProdBrand.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B","sourcesContent":[".importCsvCard {\n  height: 100%;\n}\n\n.addProdBrandBtnContainer {\n  display: flex;\n  justify-content: center;\n  margin-top: 24px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"importCsvCard": `AddProdBrand_importCsvCard__YAA3D`,
	"addProdBrandBtnContainer": `AddProdBrand_addProdBrandBtnContainer__G6k8Y`
};
export default ___CSS_LOADER_EXPORT___;
