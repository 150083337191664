import { Button } from "antd";
import React, {
	forwardRef,
	Fragment,
	useEffect,
	useRef,
	useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting, { getProductSetting } from "../../api/getSettings";
import "./style.css";
import _ from "lodash"
import { DECIMAL_NUMBER, getMomentObj, isValidUrl } from "../../utils/functions";
// import { saveAs } from "file-saver";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {

	// const [hasHSN, setHasHSN] = useState(false);

	const converter = require('number-to-words')

	const word = converter.toWords(data?.grandTotal || 0);

	const totalInWords = word.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());

	const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
	const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);

	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

	const getProductGst = (product) => {
		return _.get(product, "productGST") || _.get(product, "vatPrice") || 0;
	}
	// useEffect(() => {
	// 	const hasHSN = data?.saleInvoiceProduct?.some(val => val?.product?.hsn_code);
	// 	setHasHSN(hasHSN);
	// }, [data?.saleInvoiceProduct]);

	return (
		<Fragment>
			<div ref={ref} className="pl-4">
				<div style={{ fontSize: "14px" }} className=" pr-4 mt-2">
					<div style={{ fontSize: "15px" }} className="mx-auto gap-5 flex px-5 pt-1 pb-0.5 border-[#090909] border-t border-l border-r font-semibold">
						{
							isValidUrl(invoiceData?.companyLogo) &&
							<div className="flex items-center justify-center">
								<img className="min-w-[50px] max-w-[150px] min-h-[50px] max-h-[80px] object-contain" src={invoiceData?.companyLogo}></img>
							</div>
						}
						<div className="">
							<div className="text-center text-lg font-bold">{invoiceData?.companyName}</div>
							<div className="flex justify-center">
								{invoiceData?.address && <div>{invoiceData?.address}</div>}
								{invoiceData?.address_l2 && <div>{invoiceData?.address_l2}</div>}
							</div>
							{invoiceData?.phone && <div className="text-center ">{invoiceData?.phone}</div>}
							{invoiceData?.email && <div className="text-center ">{invoiceData?.email}</div>}
							{invoiceData?.gst_no && <div className="text-center ">GSTIN : {invoiceData?.gst_no}</div>}
						</div>
					</div>

					<div className="flex border bg-[#c8f4f3] text-center border-[#090909]  text-black ">
						<div style={{ height: "45px", width: "800px" }} className="text-lg grid place-items-center font-bold">
							{isQuotation ? "QUOTATION" : "INVOICE"}
						</div>
						<table style={{ height: "45px", width: "160px" }} className=" font-bold text-xs text-left ">
							<tr className="border-[#090909] border-l border-b">
								<td style={{ width: "12px" }} className=" "></td>
								<td className=" border-[#090909] border-l !h-fit ">Original for Recipient</td>
							</tr>
							<tr className="border-[#090909] border-l border-b">
								<td style={{ width: "12px" }} className=" " ></td>
								<td className=" border-[#090909] border-l !h-fit ">Duplicate for Transporter</td>
							</tr >
							<tr className="border-[#090909] border-l ">
								<td style={{ width: "12px" }} className=" "></td>
								<td className=" border-[#090909] border-l !h-fit">Triplicate for Supplier</td>
							</tr>
						</table>

					</div>
					<div className="grid grid-cols-3 text-left border-r border-b border-l border-[#090909]  ">
						<div className="col-span-1 text-left border-r  border-[#090909] p-0.5">
							<div className="flex">
								<div className="w-1/2">Reverse Charge</div>
								<div className="w-1/2 ">: No</div>
							</div>
							<div className="flex">
								<div className="w-1/2">{isQuotation ? "Quotation" : "Invoice"} No </div>
								<div className="w-1/2">: {data?.manualId}</div>
							</div>
							<div className="flex">
								<div className="w-1/2">{isQuotation ? "Quotation" : "Invoice"} Date</div>
								<div className="w-1/2">: {getMomentObj(data?.date).format("DD-MM-YYYY")}</div>
							</div>
							<div className="flex">
								<div className="w-1/2">State</div>
								<div className="w-1/2">: </div>
							</div>
						</div>

						<div className="col-span-1 p-0.5 ">
							<div className="flex">
								<div className="w-1/2">Challan No. </div>
								<div className="w-1/2">: { }</div>
							</div>
							<div className="flex">
								<div className="w-1/2">Vehicle No.</div>
								<div className="w-1/2">: {data?.vehicleNumber}</div>
							</div>
							<div className="flex">
								<div className="w-1/2">Date of Supply</div>
								<div className="w-1/2">: { }</div>
							</div>
							<div className="flex">
								<div className="w-1/2">Place of Supply</div>
								<div className="w-1/2">: { }</div>
							</div>
						</div>

						<div className="col-span-1  border-l border-[#090909] text-left p-0.5">
							<div className="flex">
								<div className="w-1/2">TransporterName</div>
								<div className="w-1/2">: </div>
							</div>
							<div className="flex">
								<div className="w-1/2">LR Number </div>
								<div className="w-1/2">: { }</div>
							</div>
							<div className="flex">
								<div className="w-1/2">Po Number</div>
								<div className="w-1/2">: {data?.poNumber}</div>
							</div>
							<div className="flex">
								<div className="w-1/2">Po Date </div>
								<div className="w-1/2">: {getMomentObj(data?.poDate).format("DD-MM-YYYY")}</div>
							</div>

							{/* <p>TransporterName </p>
							        <p>LR Number  </p>
								    <p>Po Number </p>
								    <p>Po Date    </p> */}


							{/* {data?.customer?.vehicle_number && <p>Vehicle Number : <span>{data?.customer?.vehicle_number}</span></p>} */}
							{/* <p className="px-1">: <span>{}</span></p>
								    <p className="px-1">: <span>{}</span></p>
								    <p className="px-1">: <span>{data?.poNumber}</span></p>
								    <p className="px-1">: <span>{moment(data?.poDate).format("YYYY-MM-DD")}</span></p> */}
						</div>
					</div>

					<div className="grid grid-cols-2 text-left border-l border-r border-[#090909] ">
						<div className="col-span-1">
							<div className=" border-b border-[#090909] bg-[#c8f4f3] text-center text-black text-base font-bold">
								{isQuotation ? "Quotation for" : "Details of Receiver | Billed to:"}
							</div>
							<div className=" text-left p-0.5">
								<div className="flex">
									<div className="w-1/6">Name</div>
									<div className="w-5/6">: {data?.customer?.name && <span>{data?.customer?.name}</span>}</div>
								</div>
								<div className="flex">
									<div className="w-1/6">Address</div>
									<div className="w-5/6">: {data?.customer?.address && <span>{data?.customer?.address}</span>}</div>
								</div>
								<div className="flex">
									<div className="w-1/6">GSTIN</div>
									<div className="w-5/6">: {data?.customer?.gst_no && <span>{data?.customer?.gst_no}</span>}</div>
								</div>
								<div className="flex">
									<div className="w-1/6">State</div>
									<div className="w-5/6">: {data?.customer?.state && <span>{data?.customer?.state}</span>}</div>
								</div>
								{/* <span classname=""> state code : 33</span> */}

								{/* <p>Phone : {data?.customer?.phone && <span>{data?.customer?.phone}</span>}</p>
								<p>Email : {data?.customer?.email && <span>{data?.customer?.email}</span>}</p> */}
							</div>
						</div>
						<div className="col-span-1  border-l border-[#090909]">
							<div className=" border-b border-[#090909] bg-[#c8f4f3] text-center text-black text-base font-bold">
								Details of Consignee | Shipped to:
							</div>
							<div className="text-left p-0.5">
								<div className="flex">
									<div className="w-1/6">Name</div>
									<div className="w-5/6">: {data?.customer?.name && <span>{data?.customer?.name}</span>}</div>
								</div>
								<div className="flex gap-2">
									<div className="w-1/6">Address</div>
									{/* <div className="px-2">: {(data?.customer?.address || data?.address) && <p>{data?.address ? <span>{data?.address}</span> : <span>{data?.customer?.address}</span>}</p>}</div> */}

									{data?.customer?.address || data?.address ? (
										<div className="w-5/6 pl-0.5">
											{data?.address ? <p>{data?.address}</p> : <p>{data?.customer?.address}</p>}
										</div>) : null}
								</div>
								<div className="flex">
									<div className="w-1/6">GSTIN</div>
									<div className="w-5/6">: {data?.customer?.gst_no && <span>{data?.customer?.gst_no}</span>}</div>
								</div>
								<div className="flex">
									<div className="w-1/6">State</div>
									<div className="w-5/6">: </div>
								</div>

								{/* <p className="px-2">  : {data?.customer?.address &&<span>{data?.customer?.address}</span>}</p>
								   <p className="px-2">  : {(data?.customer?.address || data?.address) && <p>{data?.address ? <span>{data?.address}</span> : <span>{data?.customer?.address}</span>}</p>}</p> */}

							</div>
						</div>
					</div>

					<div></div>

					<table className="w-full border border-[#090909] ">
						<thead className="border-b border-[#090909] bg-[#c8f4f3]">
							<tr className="">
								<th style={{ width: "30px" }} className="border-r border-[#090909] text-center"  >Sr. No.</th>
								<th className="border-r border-[#090909] text-center"  >Name of Product </th>
								{/* {hasHSN  && <th className="border-r border-[#090909] text-center" >HSN</th>} */}
								<th style={{ width: "30px" }} className="border-r border-[#090909] text-center" >HSN</th>
								<th style={{ width: "45px" }} className="border-r border-[#090909] text-center"  >QTY</th>
								<th style={{ width: "45px" }} className="border-r border-[#090909] text-center"  >Unit</th>
								<th style={{ width: "" }} className="border-r border-[#090909] text-center"  >Rate</th>
								{vatAmount !== 0 && <th style={{ width: "70px" }} className="border-r border-[#090909] text-center" rowspan="1.5" >Taxable Value</th>}
								{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#090909] text-center" colspan="2"> CGST
									<div className="flex justify-between  border-t border-[#090909]">
										<div className="">Rate </div>
										<div className="">Amount</div>
									</div></th>}
								{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#090909] text-center" colspan="2">SGST
									<div className="flex justify-between border-t border-[#090909]">
										<div className="">Rate </div>
										<div className="">Amount</div>
									</div></th>}
								{storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <th className="border-r border-[#090909] text-center" colspan="4">IGST
									<div className="flex justify-between border-t border-[#090909]">
										<div className="">Rate </div>
										<div className="">Amount</div>
									</div></th>}
								<th style={{ width: "70px" }} className="border-r border-[#090909] text-center" rowspan="1.5" >Total</th>
							</tr>
						</thead>
						<tbody>
							{data &&
								_.filter(isQuotation ? data.quotationProduct : data.saleInvoiceProduct, (each) => each).map((d, i) => (
									<tr className="border-b border-[#090909]">
										<td className="text-center border-r border-[#090909]">{i + 1}</td>
										<td className="text-left pl-0.5 border-r border-[#090909]">
											<div>{d.product.name}</div>
											{invoiceData?.show_description_pdf_invoice === true && (
												<div className="text-sm">{d.description}</div>
												//This is for Remove <p></p> tag <div className="text-sm" dangerouslySetInnerHTML={{__html:d.product.description}}></div> 
											)}
										</td>
										{/* {hasHSN && <td className="text-center border-r border-[#090909]">{d.product.hsn_code}</td>} */}
										<td className="text-center border-r border-[#090909]">{d.hsn_code}</td>
										<td className="text-center border-r border-[#090909]">{d.productQuantity}</td>
										<td className="text-center border-r border-[#090909]">{d.product.unitType}</td>
										<td className="text-right border-r border-[#090909]">{d.productSalePrice}</td>
										{vatAmount !== 0 && <td className="text-right border-r border-[#090909] bg-[#c8f4f3]">{DECIMAL_NUMBER(d.productQuantity * d.productSalePrice)}</td>}
										{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td className="text-center border-r border-[#090909]">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits) / 2}%</td>}
										{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td className="text-center border-r border-[#090909]">{DECIMAL_NUMBER((((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity), noOfDecimalDigits) / 2}</td>}
										{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td className="text-center border-r border-[#090909]">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits) / 2}%</td>}
										{(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td className="text-center border-r border-[#090909]">{DECIMAL_NUMBER((((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity), noOfDecimalDigits) / 2}</td>}
										{storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "60px" }} className="text-center border-r border-[#090909]" colSpan={2}>{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
										{storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "60px" }} className="text-center border-r border-[#090909]" colSpan={2}>{DECIMAL_NUMBER(((((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity)), noOfDecimalDigits)}</td>}
										<td style={{ width: "80px" }} className="text-right border-r border-[#090909] font-bold">{DECIMAL_NUMBER(((d.productQuantity * d.productSalePrice) + (((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity)), noOfDecimalDigits)}</td>

										{/* <td style={{width:"80px"}} className="text-right border-r border-[#090909] font-bold"> {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 ? (DECIMAL_NUMBER(((d.productQuantity * d.productSalePrice) + (((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity)), noOfDecimalDigits)) :
                                             (DECIMAL_NUMBER(((d.productQuantity * d.productSalePrice) + (((d.productSalePrice ) / 100) * d.productQuantity)), noOfDecimalDigits))}</td> */}
									</tr>
								))
							}
							{/* leaving space when only 1-4 products */}
							{data?.productQty < 5 && <div className="min-h-[100px]" />}
						</tbody>

						{/* <td style={{width:"80px"}} className="text-right border-r border-[#090909] font-bold">
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 ? (DECIMAL_NUMBER(((d.productQuantity * d.productSalePrice) + (((d.productSalePrice * getProductGst(d)) / 100) * d.productQuantity)), noOfDecimalDigits)
                                            ) : (DECIMAL_NUMBER(((d.productQuantity * d.productSalePrice) + (((d.productSalePrice ) / 100) * d.productQuantity)), noOfDecimalDigits)  )}
										</td> */}
						{/* {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td className="text-center border-r border-[#090909]" colSpan={2}>{DECIMAL_NUMBER((((d.productSalePrice / (1+ (getProductGst(d) / 100))) * d.productQuantity)-(d.productSalePrice)), noOfDecimalDigits)}</td>} */}
						{/* {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && (<td className="text-center border-r border-[#090909]" colSpan={2}>{((d.productSalePrice / (1 + (getProductGst(d) / 100))) * d.productQuantity) - d.productSalePrice < 0 ?'' :''}{DECIMAL_NUMBER(Math.abs(((d.productSalePrice / (1 + (getProductGst(d) / 100))) * d.productQuantity) - (d.productSalePrice)), noOfDecimalDigits)}</td>)} */}

						<tfoot className="bg-[#c8f4f3]">
							<tr>
								<th className="text-center  border-r border-[#090909]" colspan="2">Total Quantity</th>
								<th className="text-center  border-r border-[#090909]" colspan=""></th>
								<td className="text-center font-bold">{data?.productQty}</td>
								<th className="text-center  border-r border-[#090909]" colspan="2"></th>
								{vatAmount !== 0 && <th className="text-center  border-r border-[#090909]"></th>}
								{vatAmount !== 0 && <th className="text-center  border-r border-[#090909]" colspan="2"></th>}
								{vatAmount !== 0 && <th className="text-center  border-r border-[#090909]" colspan="2"></th>}
								<th className="text-right border-r border-[#090909]">{DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</th>
							</tr>
						</tfoot>
					</table>

					<div className="grid grid-cols-12 border-r border-l border-[#090909] ">
						<div className="col-span-7 border-r border-[#090909]">
							<div className="text-center border-b border-[#090909] p-1">
								<div className="font-semibold bg-[white] mb-2 ">Total {isQuotation ? "Quotation" : "Invoice"} Amount in words</div>
								<div>{totalInWords} Only</div>
							</div>

							<div className="p-0.5">
								{(invoiceData?.acc_holder_name || invoiceData?.acc_number || invoiceData?.acc_ifsc || invoiceData?.bank_name || invoiceData?.bank_branch) && <h4 className="text-center font-semibold">Bank Details</h4>}
								<div style={{ fontSize: "12px" }} className="grid grid-cols-10 text-left">
									<div className="col-span-4">
										{invoiceData?.acc_holder_name && <div>Account Holder Name</div>}
										{invoiceData?.acc_number && <div>Bank Account Number</div>}
										{invoiceData?.acc_ifsc && <div>Bank IFSC Code</div>}
										{invoiceData?.bank_name && <div>Bank Name</div>}
										{invoiceData?.bank_branch && <div>Bank Branch Name</div>}
									</div>
									<div className="col-span-6">
										{invoiceData?.acc_holder_name && <div>: {invoiceData?.acc_holder_name}</div>}
										{invoiceData?.acc_number && <div>: {invoiceData?.acc_number}</div>}
										{invoiceData?.acc_ifsc && <div>: {invoiceData?.acc_ifsc}</div>}
										{invoiceData?.bank_name && <div>: {invoiceData?.bank_name}</div>}
										{invoiceData?.bank_branch && <div>: {invoiceData?.bank_branch}</div>}
									</div>
								</div>
							</div>

						</div>
						<div className="col-span-5 p-2">
							{storeGSTCode === customerGSTCode || !customerGSTCode ? <div>
								{vatAmount !== 0 && <div className='flex justify-end w-full'>
									<span className="w-full text-left">Add:CGST</span>
									<span className="w-full text-right">₹{(DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits) / 2)}</span>
								</div>}

								{vatAmount !== 0 && <div className='flex justify-end w-full border-b border-dashed mb-2 border-[#000]'>
									<span className="w-full text-left">Add:SGST</span>
									<span className="w-full text-right">₹{(DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits) / 2)}</span>
								</div>}
							</div> :
								<div>
									{vatAmount !== 0 && <div className='flex justify-end w-full border-b border-dashed mb-2 border-[#000]'>
										<span className="w-full text-left">IGST</span>
										<span className="w-full text-right">₹{DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits)}</span>
									</div>}
								</div>}

							{data?.totalAmount != 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left">Sub Total</span>
								<span className="w-full  text-right">₹{DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)}</span>
							</div>}

							{data?.returnTotal > 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left">Return Total</span>
								<span className="w-full  text-right">- ₹{data.returnTotal}</span>
							</div>}

							{data?.discount != 0 && <div className='flex justify-end w-full'>
								<span className="w-full text-left">Discount(-)</span>
								{/* <span className="w-full  text-right">{data?.discount_type == "amount" ? "₹" : " "}{(DECIMAL_NUMBER(data?.discount || 0), noOfDecimalDigits)}{data?.discount_type == "percentage" ? "%" : " "} </span> */}
								<span className="w-full text-right">
									{data?.discount_type === "amount" ? `₹${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}` : `${DECIMAL_NUMBER(data?.discount || 0, noOfDecimalDigits)}%`}
								</span>
							</div>}

							<div className='flex justify-end w-full border-t border-dashed mt-2 py-2 border-[#000]'>
								<span className="w-full text-left">Amount With Tax</span>
								<span className="w-full  text-right">₹{DECIMAL_NUMBER((data.grandTotal || 0), noOfDecimalDigits)}</span>
							</div>

							{(data.frightCharges !== 0) && (
								<div className="flex">
									<span className="w-full text-left">Fright Charges</span>
									<span className="w-full  text-right">₹{data?.frightCharges}</span>
								</div>)}

						</div>
					</div>

					<div className="grid grid-cols-12 border border-[#090909]">
						<div className="col-span-6 text-center border-r border-[#090909] p-1">
							{(invoiceData?.terms_conditions || data?.terms_conditions) &&
								<>
									<div className="font-bold">
										Terms And Conditions
									</div>
									<ol className="text-left text-sm list-inside">
										<li dangerouslySetInnerHTML={{ __html: data?.terms_conditions ? data?.terms_conditions : invoiceData?.terms_conditions }}></li>
									</ol>
								</>}
						</div>
						<div className="col-span-6 text-center p-1">
							<div className="text-sm">
								Certified that the particular given above are true and correct
							</div>
							<div className="font-bold text-lg"><h2>For, {invoiceData?.companyName}</h2></div>
							<div className="flex justify-end h-18">{invoiceData?.signatureSnapshot && <img className="h-[70px]" src={invoiceData?.signatureSnapshot} />}</div>
							<div className="text-sm mt-7">Authorised Signatory</div>
						</div>
					</div>
				</div>

			</div>
		</Fragment>
	);
});

const SaleInvoice = ({ data, vatAmount, styles = {}, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
	const componentRef = useRef();
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
		onAfterPrint: () => successCallBack?.(),
	});

	const [invoiceData, setInvoiceData] = useState(null);
	const [productSettings, setProductSettings] = useState(null);
	useEffect(() => {
		getSetting().then((data) => setInvoiceData(data.result));
		getProductSetting().then((data) => setProductSettings(data.result));
	}, []);

	// const handleDownloadPdf = () => {
	// 	const pdfBlob = componentRef.current;
	// 	saveAs(pdfBlob, 'downloaded_invoice.pdf');
	//   };

	return (
		<div>
			<div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }}>
				<PrintToPdf
					ref={componentRef}
					data={data}
					invoiceData={invoiceData}
					vatAmount={vatAmount}
					isQuotation={isQuotation}
					productSettings={productSettings}
				/>
			</div>
			{invoiceData && (
				<>
					<Button style={styles} type='primary' shape='round' onClick={() => {
						if (_.isFunction(preCall)) {
							preCall(handlePrint);
						} else {
							handlePrint()
						}
					}}>
						A4 Print2
					</Button>
					{/* <Button type='primary' shape='round' onClick={handleDownloadPdf}>
				Download PDF
			</Button> */}
				</>
			)}
		</div>
	);
};

export default SaleInvoice;
