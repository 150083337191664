import React from 'react'

import { Button, Collapse, DatePicker, Form, Input, InputNumber, Select } from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadAllCustomer } from "../../redux/rtk/features/customer/customerSlice";
import { loadAllProduct, loadProduct } from "../../redux/rtk/features/product/productSlice";
import { loadSingleSale, updateSale } from "../../redux/rtk/features/sale/saleSlice";
import Products from "./Products";
import "./sale.css";

import dayjs from "dayjs";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { loadAllSale } from "../../redux/rtk/features/sale/saleSlice";
import { loadAllStaff } from "../../redux/rtk/features/user/userSlice";
import { loadAllVatTax } from "../../redux/rtk/features/vatTax/vatTaxSlice";
import BigDrawer from "../Drawer/BigDrawer";
import AddCust from "../customer/addCust";
import getStaffId from "../../utils/getStaffId";
import getSetting, { getProductSetting, getTemplateSetting } from "../../api/getSettings";
import Spinner from '../Spinner/Spinner';
import _ from 'lodash';
import { DECIMAL_NUMBER } from '../../utils/functions';
import MOPSelector from '../MOPModal/MOPSelector';
import { MODE_OF_PAYMENTS, textEditorFormats, textEditorModule } from '../../utils/constants';
import SaleInvoiceA4 from "../Invoice/SaleInvoiceA4";
import SaleInvoiceA5 from "../Invoice/SaleinvoiceA5";
import SaleInvoice from '../Invoice/SaleInvoice';
import SaleInvoiceV1 from "../Invoice/SaleInvoiceV1";
import SaleInvoiceV1A5 from "../Invoice/SaleInvoiceV1A5";
import SaleInvoiceV2A4 from "../Invoice/SaleInvoiceV2A4";
import SaleInvoiceV3A4 from '../Invoice/SaleInvoiceV3A4';
import PosPrint from '../Invoice/PosPrint';
import PosPrint1 from '../Invoice/PosPrint1';
import PosPrint3inch from "../Invoice/PosPrint3inch";
import PosPrint4inch from "../Invoice/PosPrint4inch";
import PosPrint3inch1 from '../Invoice/PosPrint3inch1';
import PosPrint4inch1 from '../Invoice/PosPrint4inch1';
import ReactQuill from 'react-quill';

export const RenderAdditionalDetails = ({ updateState }) => {
	const handleChange = (e) => {
		const { name, value } = e.target;
		if (_.isFunction(updateState)) {
			updateState(name, value)
		}
	}
	return <>
	    <div className="w-full flex gap-5">
		     <Form.Item className='w-1/2' label='Po Number' name='poNumber'>
		     	<Input
		     		className=''
		     		placeholder='Enter Po Number'
		     		size={"small"}
		     		// style={{ marginBottom: "10px" }}
		     		onChange={handleChange}
		     	/>
		     </Form.Item>
     
		     <Form.Item className='w-1/2' label='Po Date' name='poDate'>
		     	<DatePicker
		     		className=''
		     		placeholder='Select Po Date'
		     		size={"small"}
		     		// style={{ marginBottom: "10px" }}
		     		onChange={(value) => updateState("poDate", value)}
		     	/>
		     </Form.Item>
		</div>
		<div className="w-full flex gap-5">
		     <Form.Item className='w-1/2' label='Vehicle Number' name='vehicleNumber'>
		     	<Input
		     		className=''
		     		placeholder='Enter Vehicle Number'
		     		size={"small"}
		     		// style={{ marginBottom: "10px" }}
		     		onChange={handleChange}
		     	/>
		     </Form.Item>

			 <Form.Item className='w-1/2' label='Fright Charges' name='frightCharges'>
		     	<Input
		     		className=''
		     		placeholder='Enter Fright Charges'
		     		size={"small"}
		     		// style={{ marginBottom: "10px" }}
		     		onChange={handleChange}
		     	/>
		     </Form.Item>

		</div>
		<div className="w-full flex gap-5">
		     <Form.Item className='w-1/2' label='Delivery Note Date' name='deliveryNoteDate'>
			 <DatePicker
		     		className=''
		     		placeholder='Select Delivery Note Date'
		     		size={"small"}
		     	    onChange={(value) => updateState("deliveryNoteDate", value)}
		     	/>
		     </Form.Item>

			 <Form.Item className='w-1/2' label='Destination' name='destination'>
		     	<Input
		     		className=''
		     		placeholder='Enter Destination'
		     		size={"small"}
		     		onChange={handleChange}
		     	/>
		     </Form.Item>
		</div>
		<div className="">
		     <Form.Item className='w-1/2' label='Dated' name='buyerDated'>
			 <DatePicker
		     		className=''
		     		placeholder='Select Dated'
		     		size={"small"}
		     		onChange={(value) => updateState("buyerDated", value)}
		     	/>
		     </Form.Item>
	    </div>
	</>
}

const UpdateSale = () => {
          
    const { Option } = Select;
	const [state, setState] = useState({
		totalAmount: 0,
		discount: 0,
		discount_type: "",
		afterDisc: 0,
		totalPayable: 0,
		paidAmount: 0,
		afterVatTaxAdded: 0,
		dueAmount: 0,
		totalVatAmount: 0,
		customerId: "",
		poNumber: "",
		poDate: "",
		vehicleNumber: "",
		frightCharges: 0,
		deliveryNoteDate: "",
		destination: "",
		buyerDated: ""
	});
	const [loader, setLoader] = useState(false);
	const [subTotal, setSubTotal] = useState([]);
	const [returnTotal, setReturnTotal] = useState(0);
	const [openAddCustomer, setOpenAddCustomer] = useState(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	// Form Function
	const [form] = Form.useForm();

	const allCustomer = useSelector((state) => state.customers.list);
	const { allList: productList, loading: productLoading } = useSelector(
		(state) => state.products
	);
	const { loading: loadingSale } = useSelector(
		(state) => state.sales
	);

	const [invoiceData, setInvoiceData] = useState(null);
	const [productSettings, setProductSettings] = useState(null);
	const [templateData, setTemplateData] = useState(null);
	const [terms, setTerms] = useState("");

	useEffect(() => {
		getSetting().then((data) => setInvoiceData(data?.result));
		getProductSetting().then((data) => setProductSettings(data.result));
		getTemplateSetting().then((data) => setTemplateData(data.result));
	}, []);

	useEffect(() => {
		if (!sale || sale.id != id) {
			dispatch(loadSingleSale(id));
		}
	}, [id]);

	const staffId = getStaffId();
	const [userId, setUserId] = useState(staffId);
	const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

	const allStaff = useSelector((state) => state.users.list);
	const { list: vatTaxList, loading: vatTaxLoading } = useSelector(
		(state) => state.vatTax
	);

	useEffect(() => {
		dispatch(loadAllStaff({ status: "true" }));
		dispatch(loadAllCustomer({ page: 1, count: 100000 }));
		dispatch(loadAllProduct({ page: 1, count: 100000 }));
		dispatch(loadAllVatTax());
	}, [dispatch]);

	const { sale, loading } = useSelector((state) => state?.sales);

	useEffect(()=>{
		if (sale?.singleSaleInvoice?.saleInvoiceProduct) {
			totalCalculator();
		}
	},[sale?.singleSaleInvoice])

	useEffect(() => {
		if (sale?.singleSaleInvoice) {
			if (sale?.singleSaleInvoice?.terms_conditions) {
				form.setFieldValue("terms_conditions", sale?.singleSaleInvoice?.terms_conditions);
				setTerms(sale?.singleSaleInvoice?.terms_conditions);
			}
			else if (invoiceData?.terms_conditions) {
				form.setFieldValue("terms_conditions", invoiceData.terms_conditions);
				setTerms(invoiceData.terms_conditions);
			}
		}
	}, [invoiceData, sale?.singleSaleInvoice]);

	// const handleAmountChange = (amount) => {
	// 	form.setFieldValue('discount', amount);
	// 	if (amount !== 0) {
	// 		// (1 / 10) * 100 
	// 	  const percentageEquivalent = (amount / 10) * 100;
	// 	  form.setFieldValue('discount_percentage', percentageEquivalent);
	// 	} else {
	// 	  form.setFieldValue('discount_percentage', 0);
	// 	}
	//   };

	//   const handlePercentageChange = (percentage) => {
	// 	form.setFieldValue('discount_percentage', percentage);
	// 	if (percentage !== 0) {
	// 		//  (10 / 100) * 10 
	// 	  const discountAmount = (percentage / 100) * 10;
	// 	  form.setFieldValue('discount', discountAmount);
	// 	} else {
	// 	  form.setFieldValue('discount', 0);
	// 	}
	//   };

	//   useEffect(() => {
	// 	totalCalculator()
	//   },[form.getFieldValue('discount',form.getFieldValue('discount_percentage'))])

	const formatPayload = (values) => {
		const filteredArray = values.saleInvoiceProduct.filter(obj => obj.productId !== undefined);

		// const mergedObject = filteredArray.reduce((accumulator, currentObject) => {
		// 	const productId = currentObject.productId;
		// 	if (!accumulator[productId]) {
		// 		accumulator[productId] = { ...currentObject };
		// 	} else {
		// 		accumulator[productId].productQuantity += currentObject.productQuantity;
		// 	}
		// 	return accumulator;
		// },
		// 	{}
		// );

		// const mergedArray = Object.values(mergedObject);

		return {
			...values,
			userId: userId,
			saleInvoiceProduct: filteredArray,
		};
	}

	const onFormSubmit = async (values) => {
		try {
			setLoader(true);

			const data = formatPayload(values);
			if (values.modeOfPayment !== _.last(MODE_OF_PAYMENTS).value) {
				_.set(data, "modeOfPayments", [{
					modeOfPayment: values.modeOfPayment,
					amount: values.paidAmount
				}])
			}

			const resp = await dispatch(updateSale({ values: data, saleId: sale?.singleSaleInvoice?.id }));

			if (resp.payload.message === "success") {
				form.resetFields();
				setLoader(false);
				toast.success("Product Updated ");
				dispatch(
					loadAllSale({
						page: 1,
						count: "",
						startdate: moment().format("YYYY-MM-DD"),
						enddate: moment().format("YYYY-MM-DD"),
						user: "",
					})
				);

				navigate(`/admin/sale/${resp.payload?.updatedInvoiceId}`);

			} else {
				setLoader(false);
				toast.error(resp.payload?.data?.message || "Error while sales");
			}

		} catch (error) {
			console.log(error.message);
			setLoader(false);
			toast.error("Error while sales");
		}
	};

	// validation handlers
	const validatePaidAmount = (_, value) => {
		if (value >= 0 && value <= state.totalPayable) {
			return Promise.resolve();
		}
		return Promise.reject(
			`Paid amount must be gater than or equal ${state.totalPayable}`
		);
	};

	const validateDiscount = (_, value) => {
		if (value >= 0 && value <= state.totalAmount) {
			return Promise.resolve();
		}
		return Promise.reject(`Discount must be greater than or equal to ${state.totalAmount}`);
	};

	// total calculate
	const totalCalculator = () => {
		const productArray = form.getFieldValue("saleInvoiceProduct");
		let totalVatAmount = 0;

		const subTotal = productArray?.reduce((subTotal, current) => {
			const quantity = current?.productQuantity || 0;
			const price = current?.productSalePrice || 0;
			let vat = current?.vatPrice || 0;
			// const totalVat = (vat / 100) * price * quantity;		
			if (current?.inclusiveGst) {
				totalVatAmount += (price - price / (1 + vat / 100)) * quantity || 0;
				vat = 0;
			} else {
				totalVatAmount += ((price * quantity) * (vat / 100)) || 0;
			}
			const totalVat = ((price * quantity)  * (vat/ 100 )) || 0;
			return [
				...subTotal,
				{
					subVat: current?.productVat || 0,
					// subDiscount: current?.productVat || 0quantity,
					discount: current?.product?.discount || 0,
					discount_type: current?.product?.discount_type || 0,
					subPrice: ((price * quantity) + totalVat ) || 0,
					productMrpPrice: current?.product?.productMrpPrice,
					productQuantity: current?.product?.productQuantity,
					description: current?.product?.description,
					hsn_code: current?.product?.hsn_code,
					frightCharges: current?.frightCharges || 0

				},
			];
		}, []) || [];

		setState(prevState => ({ ...prevState, "totalVatAmount": totalVatAmount }));
		setSubTotal(subTotal);

		const total = DECIMAL_NUMBER(subTotal.reduce((total, current) => total + (current.subPrice > 0 ? current.subPrice : 0), 0) || 0, noOfDecimalDigits);
		const returnTotalSum = DECIMAL_NUMBER(Math.abs(subTotal.reduce((total, current) => total + (current.subPrice < 0 ? current.subPrice : 0), 0) || 0), noOfDecimalDigits);
		setReturnTotal(returnTotalSum);
		form.setFieldValue("returnTotal", returnTotalSum);

		// const result = subTotal.reduce((acc, item) => {
			
		// 	acc.cgst += item.cgst || 0; 
		// 	acc.sgst += item.sgst || 0;
		// 	acc.igst += item.igst || 0;
		// 	acc.product_discount += item.discount || 0; 
		// 	const price = parseFloat(item.price) || 0;
		// 	const qty = parseFloat(item.qty) || 0;

		// 	acc.subtotal = acc.subPrice + price * qty;

		// 	let tax = 0;
		// 	acc.same_state = false;

		// 	if (distributor_type == 0) {
		// 	  if (warehouse_detail.state_id === shipping_state_id) {
		// 		acc.same_state = true;
		// 	  }
		// 	} else if (shipping_state_id === stockist_state_id) {
		// 	  acc.same_state = true;
		// 	}

		// 	if (acc.same_state === true) {
		// 	  tax = acc.sgst + acc.cgst;
		// 	} else {
		// 	  tax = acc.igst;
		// 	}

		// 	acc.totalTax = tax;

		// 	return acc;
		//   }, {
		// 	subtotal: 0,
		// 	product_discount: 0,
		// 	cgst: 0,
		// 	sgst: 0,
		// 	igst: 0,
		// 	totalTax: 0,
		// 	same_state: false,
		//   });

		updateState("totalAmount", total);

		const discount = form.getFieldValue("discount") || 0;

		const discount_type = form.getFieldValue("discount_type") || 0;

		let afterDiscount = 0

		if (discount_type == 'percentage') {
			afterDiscount = DECIMAL_NUMBER((Boolean(total) ? total - (total * (discount / 100)) || 0 : 0) - returnTotalSum, noOfDecimalDigits);
		} else {
			afterDiscount = DECIMAL_NUMBER((Boolean(total) ? total - (discount) || 0 : 0) - returnTotalSum, noOfDecimalDigits);
		}

		updateState("afterDisc", afterDiscount);

		// GST calculate
		const vatFields = form.getFieldValue("vatId");
		const totalVatArray =
			vatFields?.map((id) => {
				return vatTaxList.find((item) => id === item.id)?.percentage;
			}) || [];
		const TotalTaxVatPercent = totalVatArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
		const afterVatTaxAdded = DECIMAL_NUMBER(afterDiscount + (TotalTaxVatPercent / 100) * total, noOfDecimalDigits);

		updateState("totalPayable", afterVatTaxAdded);

		//due count
		const due = DECIMAL_NUMBER(Boolean(afterVatTaxAdded) ? afterVatTaxAdded - (form.getFieldValue("paidAmount") || 0) || 0 : 0, noOfDecimalDigits);
		updateState("dueAmount", due);
		// form.setFieldValue('paidAmount',afterVatTaxAdded)
	};

	if(!form.getFieldValue('userId')&& allStaff && allStaff?.length){
		form.setFieldValue('userId',allStaff?.[0]?.id)
	}

	const updateState = (key, value) => {
		setState(prevState => ({ ...prevState, [key]: value }));
		form.setFieldValue(key, value);
	}

	useEffect(() => {
		if (!form.getFieldValue('customerId') && allCustomer && allCustomer?.length, sale) {
			updateState("customerId", sale?.singleSaleInvoice?.customer?.id);
		}
	}, [sale, allCustomer]);

	useEffect(() => {
		if (sale) {
			updateState("totalAmount", sale?.singleSaleInvoice?.totalAmount);
			updateState("discount", sale?.singleSaleInvoice?.discount);
			updateState("discount_type", sale?.singleSaleInvoice?.discount_type);
			updateState("afterDisc", sale?.singleSaleInvoice?.grandTotal);
			updateState("totalPayable", sale?.singleSaleInvoice?.grandTotal);
			updateState("paidAmount", sale?.singleSaleInvoice?.paidAmount);
			updateState("dueAmount", sale?.singleSaleInvoice?.dueAmount);
			updateState("poNumber", sale?.singleSaleInvoice?.poNumber);
			updateState("destination", sale?.singleSaleInvoice?.destination);
			if (moment(sale?.singleSaleInvoice?.poDate).isValid()) {
				updateState("poDate", dayjs(sale?.singleSaleInvoice?.poDate));
			}
			if (moment(sale?.singleSaleInvoice?.poDate).isValid()) {
				updateState("deliveryNoteDate", dayjs(sale?.singleSaleInvoice?.deliveryNoteDate));
			}
			if (moment(sale?.singleSaleInvoice?.poDate).isValid()) {
				updateState("buyerDated", dayjs(sale?.singleSaleInvoice?.buyerDated));
			}
			if (moment(sale?.singleSaleInvoice?.date).isValid()) {
				updateState("date", dayjs(sale?.singleSaleInvoice?.date));
			}
			updateState("vehicleNumber", sale?.singleSaleInvoice?.vehicleNumber);
			if (sale?.singleSaleInvoice?.modeOfPayments?.length > 1) {
				const filteredModeOfPayments = _.filter(sale?.singleSaleInvoice?.modeOfPayments, "amount");
				if (filteredModeOfPayments.length > 1) {
					form.setFieldValue("modeOfPayment", "Multiple");
					form.setFieldValue("modeOfPayments", sale?.singleSaleInvoice?.modeOfPayments);
				}
			}
			form.setFieldValue("manualId", sale?.singleSaleInvoice?.manualId);
			form.setFieldValue("invoicePrefix", sale?.singleSaleInvoice?.invoicePrefix);
		}
	}, [sale]);

	const handleAddCustomerSuccess = () => {
		setOpenAddCustomer(false);
	}
	const prodTermsHandler = (val) => {
		setTerms(val);
	};

	return (
		<Form
			form={form}
			name='dynamic_form_nest_item'
			onFinish={onFormSubmit}
			onFinishFailed={() => {
				setLoader(false);
			}}
			layout='vertical'
			size='large'
			autoComplete='off'
			initialValues={{
				paidAmount: 0,
				discount: 0,
				discount_type: "percentage",
				date: dayjs(),
				vatId: [],
			}}
			// onLoad={totalCalculator}
			// initialValues={{...sale}}
			>
            <h1 className='text-center font-bold text-2xl mb-2 dark:text-white'>Update Sale</h1>
			{
				loadingSale &&
				<Spinner />
			}
			<Products
				form={form}
				totalCalculator={totalCalculator}
				subTotal={subTotal}
				productList={productList}
				productLoading={productLoading}
				invoiceData={invoiceData}
				data={sale?.singleSaleInvoice}
			/>
			<div className='flex gap-6 mb-2'>
				<div className='w-1/2'>
					<div className='flex gap-3 items-center'>
						<Form.Item
							label='Customer'
							className='w-full'
							name='customerId'
							rules={[
								{
									required: true,
									message: "Please Select a Customer!",
								},
							]}>
							<Select
								className='w-full'
								loading={!allCustomer}
								showSearch
								placeholder='Select a customer '
								optionFilterProp='children'
								onChange={(e) => setState(prevState => ({ ...prevState, "customerId": e }))}
								>
								{allCustomer &&
									allCustomer?.map((sup) => (
										<Option key={sup.id} value={sup.id} data-name={sup.name}>
											{sup.name} - {sup.phone}
										</Option>
									))}
							</Select>
						</Form.Item>
						<BigDrawer
							className={"-mb-[36px]"}
							title={"new Customer"}
							btnTitle={"customer"}
							openFlag={openAddCustomer}
							setOpenFlag={setOpenAddCustomer}
							children={<AddCust onSuccess={handleAddCustomerSuccess} drawer={true} />}
						/>
					</div>

					{
						invoiceData?.showManualIdOnSale &&
						<Form.Item
							className=''
							label='Manual Id'
							name='manualId'
							required
							rules={[
								{
									required: true,
									message: "Please enter manual id!",
								},
							]}>
							<Input
								className=''
								placeholder='Enter Manual ID'
								size={"small"}
							/>
						</Form.Item>
					}
					{
						invoiceData?.showInvoicePrefix &&
						<Form.Item
							className=''
							label='Invoice Prefix'
							name='invoicePrefix'
							required
							rules={[
								{
									required: true,
									message: "Please enter invoice prefix!",
								},
							]}>
							<Input
								className=''
								placeholder='Enter Invoice Prefix'
								size={"small"}
							/>
						</Form.Item>
					}

					<div className='flex gap-5'>
						<Form.Item
							label='Date'
							required
							className='w-1/2'
							name='date'
							rules={[
								{
									required: true,
									message: "Please input Date!",
								},
							]}>
							<DatePicker
								style={{ marginBottom: "10px" }}
								label='date'
								size='small'
								defaultValue={dayjs()}
								format={"YYYY-MM-DD"}
							/>
						</Form.Item>

						<Form.Item
							className='w-1/2'
							label='Sales Person '
							name='userId'
							required>
							<Select
								className='w-full'
								loading={!allStaff}
								showSearch
								placeholder='Select sales person '
								optionFilterProp='children'
								defaultValue={allStaff && allStaff[0] ? allStaff[0].id : undefined}
								onChange={(value) => setUserId(value)}
								filterOption={(input, option) =>
									option.children.toLowerCase().includes(input.toLowerCase())
								}>
								{allStaff &&
									allStaff?.map((info) => (
										<Option key={info.id} value={info.id}>
											{info.username}
										</Option>
									))}
							</Select>
						</Form.Item>
					</div>

					<Form.Item className='' label='Shipping Address' name='address'>
						<Input
							className=''
							placeholder='Enter shipping address'
							size={"small"}
						/>
					</Form.Item>

					<Collapse
						className='sale-additional-details'
						size="small"
						items={[{ key: '1', label: 'Additional Details', children: <RenderAdditionalDetails updateState={updateState} /> }]}
					/>

					{/* <Form.Item className='' label='Note' name='note'>
						<Input
							className=''
							size={"small"}
							placeholder='Write sale Note'
							style={{ marginBottom: "10px" }}
							label='note'
						/>
					</Form.Item> */}

					<Form.Item
						style={{ marginBottom: "10px" }}
						name='terms_conditions'
						label='Terms & Conditions '>
						<ReactQuill
							value={terms}
							onChange={prodTermsHandler}
							modules={textEditorModule}
							formats={textEditorFormats}
						/>
					</Form.Item>

					{/* {invoiceData?.terms_conditions && <div>
						<label className="text-base my-2">Terms & Conditions</label>
						<div dangerouslySetInnerHTML={{__html: invoiceData?.terms_conditions}}></div>
					</div>} */}
				</div>
				<div className='w-1/2'>
					{" "}
					<div className='py-1'>
						{/* <div className='p-1 flex justify-between dark:text-white'>
							<strong>Total: </strong>
							<strong>{total.toFixed(2)} </strong>
						</div> */}
						<div  className='pt-0.5 flex items-centerl justify-between dark:text-white'>
							<span>Total: </span>
							<Form.Item name="totalAmount">
								<Input disabled />
							</Form.Item>
						</div>
						{
							returnTotal > 0 &&
							<div className='pt-0.5 flex items-centerl justify-between dark:text-white'>
								<span>Return: </span>
								<Form.Item name="returnTotal">
									<Input disabled />
								</Form.Item>
							</div>
						}
						<div className='flex justify-between items-center pt-0.5 dark:text-white'>
							<span className=''>Discount: </span>
							<div className="flex gap-2">
								<Form.Item
									className='mb-0'
									name='discount'
									// initialValue={sale?.singleSaleInvoice?.discount}
									rules={[
										{
											validator: validateDiscount,
										},
									]}>
									<InputNumber
										className='w-[8.8rem]'
										size={"small"}
										placeholder="Amount (₹)"
										onChange={totalCalculator}
										defaultValue={0}
										min={0}
									/>
								</Form.Item>
								<Form.Item className='mb-0 w-4/5 flex justify-end discount_type' name='discount_type'>
									<Select
										className='w-[8.8rem] !h-[44px] !flex !items-center'
										placeholder='Select Discount type'
										maxTagCount={5}
										showArrow
										size={"small"}
										onChange={() => totalCalculator()}>
										{["percentage", "amount"]?.map((item) => (
											<Option key={item} value={item}>
												{item}
											</Option>
										))}
									</Select>
								</Form.Item>
							</div>
						</div>
						{/* <div className='py-1 flex justify-between items-center mb-1 dark:text-white'>
							<span>After Discount: </span>
							<span>{afterDiscount.toFixed(2)}</span>
						</div> */}
						<div  className='pt-0.5 flex items-centerl justify-between dark:text-white'>
							<span>After Discount: </span>
							<Form.Item name="afterDisc">
								<Input value={DECIMAL_NUMBER(state.afterDisc, noOfDecimalDigits)} disabled />
							</Form.Item>
						</div>
						{/* <div className='flex justify-between items-center mb-2 dark:text-white'>
							<span className=''>GST: </span>
							<Form.Item className='mb-0 w-4/5 flex justify-end dark:text-white' name='vatId'>
								<Select
									mode='multiple'
									className='w-72 '
									placeholder='Select GST type'
									maxTagCount={5}
									showArrow
									size={"small"}
									loading={vatTaxLoading}
									onChange={() => totalCalculator()}>
									{vatTaxList?.map((item) => (
										<Option key={item.id} value={item.id}>
											{item.title}
											<span className='italic'>@{item.percentage}%</span>
										</Option>
									))}
								</Select>
							</Form.Item>
						</div> */}

						{/* <div className='py-1 flex justify-between items-center mb-1 dark:text-white'>
							<span>Total Payable: </span>
							<span>{afterVatTaxAdded.toFixed(2)}</span>
						</div> */}
						<div className='pt-0.5 flex items-centerl justify-between dark:text-white'>
							<span>Total Payable: </span>
							<Form.Item name="totalPayable">
								<Input value={DECIMAL_NUMBER(state.totalPayable, noOfDecimalDigits)} disabled />
							</Form.Item>
						</div>
						<div className='pt-0.5 flex justify-between items-center dark:text-white'>
							<span className=''>Paid Amount: </span>
							<Form.Item
								className='mb-0'
								name='paidAmount'
								// initialValue={sale?.singleSaleInvoice?.paidAmount}
								rules={[
									{
										validator: validatePaidAmount,
									},
								]}>
								<InputNumber
									className='w-72'
									onChange={() => totalCalculator()}
									defaultValue={0}
									placeholder="Paid Amount"
									min={0}
									size={"small"}
								/>
							</Form.Item>
						</div>
						{/* <div className='py-1 mb-1 flex justify-between dark:text-white'>
							<strong>Due Amount:</strong>
							<strong>{due.toFixed(2)}</strong>
						</div> */}
						<div className='pt-0.5 flex items-centerl justify-between dark:text-white'>
							<span>Due Amount: </span>
							<Form.Item name="dueAmount">
								<Input value={DECIMAL_NUMBER(state.dueAmount, noOfDecimalDigits)} disabled />
							</Form.Item>
						</div>
						<div className='py-0.5  flex justify-between dark:text-white'>
							{
								sale?.singleSaleInvoice &&
								<MOPSelector initialValue={sale?.singleSaleInvoice?.modeOfPayment} form={form} totalAmount={form.getFieldValue("paidAmount")} dropdownWidth="50%" />
							}
						</div>
					</div>
					<div className=" grid grid-cols-2 gap-4 mt-2">
					  <div className='col-span-1 '>
						{templateData?.invoiceTemplate === 'a4medic' && (
						<div className={"text-end mr-2 flex-1"}>
							<SaleInvoiceA4
								data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'a5medic' && (
						<div className={"text-end mr-2 flex-1"}>
							<SaleInvoiceA5
							    data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
							    vatAmount={state.totalVatAmount}
							    styles={{ width: "100%" }}
							    successCallBack={() => form.submit()}
							/>
						</div>
						)}
						 {templateData?.invoiceTemplate === 'a4print' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<SaleInvoice
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						 {templateData?.invoiceTemplate === 'v1a4print' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<SaleInvoiceV1
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						 {templateData?.invoiceTemplate === 'v1a5print' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<SaleInvoiceV1A5
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						{templateData?.invoiceTemplate === 'v2a4print' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<SaleInvoiceV2A4
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						{templateData?.invoiceTemplate === 'v3a4print' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<SaleInvoiceV3A4
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						{templateData?.invoiceTemplate === '3posprint' && (
                        <div className={"text-end flex-1"}>
							<PosPrint
								data={{ ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === '4posprint' && (
					    	<div className={"text-end mr-2 flex-1"}>
					    		<PosPrint1
					    			data={{ ...state, ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
					    			vatAmount={state.totalVatAmount}
					    			styles={{ width: "100%" }}
					    			successCallBack={() => form.submit()}
					    		/>
					    	</div>
					    )}
						{templateData?.invoiceTemplate === 'PosPrint3inch' && (
                        <div className={"text-end flex-1"}>
							<PosPrint3inch
								data={{ ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === 'PosPrint4inch' && (
                        <div className={"text-end flex-1"}>
							<PosPrint4inch
								data={{ ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === '3inch' && (
                        <div className={"text-end flex-1"}>
							<PosPrint3inch1
								data={{ ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
						{templateData?.invoiceTemplate === '4inch' && (
                        <div className={"text-end flex-1"}>
							<PosPrint4inch1
								data={{ ...form.getFieldsValue(), customer: allCustomer?.find(each => each.id == state.customerId), grandTotal: state.totalPayable, manualId: _.get(sale, "singleSaleInvoice.manualId") }}
								vatAmount={state.totalVatAmount}
								styles={{ width: "100%" }}
								successCallBack={() => form.submit()}
							/>
						</div>
						)}
					</div>
					<div className="col-span-1">
					<Form.Item >
						<Button
							disabled={loader}
							block
							type='primary'
							htmlType='submit'
							loading={loader}>
							Save & Preview
						</Button>
					</Form.Item>
					</div>
				  </div>
				</div>
			</div>
		</Form>
	);
}

export default UpdateSale;