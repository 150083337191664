import { Button } from "antd";
import React, {
    forwardRef,
    Fragment,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useReactToPrint } from "react-to-print";
import getSetting, { getProductSetting } from "../../api/getSettings";
// import "./style.css";
import _ from "lodash"
import { DECIMAL_NUMBER, getMomentObj, isValidUrl } from "../../utils/functions";
import converter from "number-to-words";

// import { saveAs } from "file-saver";

const PrintToPdf = forwardRef(({ data, invoiceData, vatAmount, isQuotation, productSettings }, ref) => {

    const converter = require('number-to-words')
    const storeGSTCode = (invoiceData?.gst_no)?.slice(0, 2);
    const customerGSTCode = (data?.customer?.gst_no)?.slice(0, 2);
    const word = converter.toWords(data?.totalAmount || 0);
    const words = converter.toWords(data?.vatAmount || 0);

    const totalInWords = word.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());
    const taxInWords = words.replace(/[-,]/g, '').replace(/\b\w/g, match => match.toUpperCase());

    // for table data
    const filteredData = useMemo(() => {
        return _.filter(isQuotation ? data.quotationProduct : data.saleInvoiceProduct, (each) => each)
    }, [data, isQuotation]);

    // for gst cumulative info table data
    const groupedGstData = useMemo(() => {
        const keyToGrp = _.get(filteredData, "0.productGST") ? "productGST" : "vatPrice";
        const groupByGst = _.groupBy(filteredData, keyToGrp);
        const result = []
        _.forEach(_.entries(groupByGst), ([key, value]) => {
            if (key > 0) {
                let sum = 0
                _.forEach(value, (prod) => {
                    let price = prod.productSalePrice;
                    if (prod.inclusiveGst) {
                        price = prod.productSalePrice / (1 + prod[keyToGrp] / 100)
                    }
                    sum += price * prod.productQuantity
                })
                result.push({
                    productGST: key,
                    taxableValue: sum
                })
            }
        })
        return result;
    }, [filteredData]);

    const noOfDecimalDigits = _.get(productSettings, "noOfDecimalDigits", 0);

    const getProductGst = (product, forCalculation = false) => {
        if (product.inclusiveGst && forCalculation) {
            return 0;
        }
        return _.get(product, "productGST") || _.get(product, "vatPrice") || 0;
    }

    const getProductSalePriceByGst = (product) => {
        if (product.inclusiveGst) {
            return (product.productSalePrice / (1 + (getProductGst(product) / 100)));
        }
        return product.productSalePrice;
    }

    return (
        <Fragment>
            <div ref={ref} className="p-5">
                {/* <div className="ml-80 flex justify-between "> */}
                <h1 className=" font-bold text-base flex justify-center">{isQuotation ? "QUOTATION" : "INVOICE"}  </h1>
                {/* <div className=" pr-2 font-light text-sm"> (DUPLICATE FOR TRANSPORTER)</div> */}

                {/* </div> */}
                <div className="border-2 border-[#abaaaa] text-sm ">
                    <div className="grid grid-cols-2">
                        <div className="col-span-1 border-r border-[#abaaaa]">
                            <div className="flex text-left col-span-1 p-1">
                                <div className="flex items-center">
                                    {isValidUrl(invoiceData?.companyLogo) && <img className="p-0.5 min-w-[50px] max-w-[150px] min-h-[50px] max-h-[80px] object-contain m-auto" src={invoiceData?.companyLogo}></img>}
                                </div>
                                <div>
                                    <div style={{ fontSize: 15 }} className=" font-bold">{invoiceData?.companyName}</div>
                                    {invoiceData?.address && <div className="">{invoiceData?.address} </div>}
                                    {invoiceData?.address && <div className="">{invoiceData?.address_l2 && invoiceData?.address_l2} </div>}
                                    {invoiceData?.phone && <div className="">Cell No : {invoiceData?.phone}</div>}
                                    {invoiceData?.gst_no && <div className="">GSTIN : {invoiceData?.gst_no}</div>}
                                    <div className="">State Name : {invoiceData?.state}</div>
                                    {invoiceData?.email && <div className="">E-Mail : {invoiceData?.email}</div>}
                                </div>
                            </div>
                            <div className="text-left px-1 col-span-1 border-t border-b border-[#abaaaa]">
                                <p className="pb-0.5 ">Consignee (Ship to)</p>
                                {data?.customer?.name && <div style={{ fontSize: 15 }} className=" font-bold">{data?.customer?.name}</div>}
                                {data?.customer?.address && <div className="">{data?.customer?.address} </div>}
                                {data?.customer?.phone && <div className="">Cell No :{data?.customer?.phone} </div>}
                                {/* {data?.customer?.address && <div className="">{data?.customer?.address} </div>} */}
                                {data?.customer?.gst_no && <div className="">GSTIN : {data?.customer?.gst_no}</div>}
                                {data?.customer?.state && <div className="pb-1">State Name : {data?.customer?.state}</div>}
                            </div>
                            <div className="text-left col-span-1 px-1">
                                <p className="pb-0.5">Buyer (Bill to)</p>
                                {data?.customer?.name && <div style={{ fontSize: 15 }} className=" font-bold">{data?.customer?.name}</div>}
                                {data?.customer?.address && <div className="">{data?.customer?.address} </div>}
                                {data?.customer?.phone && <div className="">Cell No :{data?.customer?.phone} </div>}
                                {/* {data?.customer?.address && <div className="">{data?.customer?.address} </div>} */}
                                {data?.customer?.gst_no && <div className="">GSTIN : {data?.customer?.gst_no}</div>}
                                {data?.customer?.state && <div className="">State Name : {data?.customer?.state}</div>}
                            </div>
                        </div>

                        <div className="col-span-1 ">
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> {isQuotation ? "Quotation" : "Invoice"} No.</h3>
                                    <h4 className="font-bold pl-2">{data?.manualId}</h4>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3>{isQuotation ? "Quotation" : "Invoice"} Date</h3>
                                    <h4 className="font-bold pl-2">{getMomentObj(data?.date).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> Delivery Note</h3>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3> Mode/Terms of Payment</h3>
                                    <h4 className="font-bold pl-2">{data?.modeOfPayment}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3>Po Number </h3>
                                    <h4 className="font-bold pl-2">{data?.poNumber}</h4>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3>Po Date </h3>
                                    <h4 className="font-bold pl-2">{getMomentObj(data?.poDate).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> Buyer's Order No.</h3>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3> Dated</h3>
                                    <h4 className="font-bold pl-2">{getMomentObj(data?.buyerDated).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> Dispatch Doc No.</h3>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3> Delivery Note Date</h3>
                                    <h4 className="font-bold pl-2">{getMomentObj(data?.deliveryNoteDate).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> Dispatched through</h3>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3> Destination</h3>
                                    <h4 className="font-bold pl-2">{data?.destination}</h4>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 border-b border-[#abaaaa]">
                                <div className="col-span-1 pl-1 text-left h-9 border-r border-[#abaaaa]">
                                    <h3> Bill of Lading/LR-RR No.</h3>
                                </div>
                                <div className="col-span-1 pl-1 text-left h-9 ">
                                    <h3> Motor Vehicle No.</h3>
                                    <h4 className="font-bold pl-2">{data?.vehicleNumber}</h4>
                                </div>
                            </div>

                            <h3 className="text-left pl-1">Terms of Delivery</h3>

                        </div>

                    </div>

                    {/* <table className="w-full border-t border-[#abaaaa]" style={{ minHeight: vatAmount !== 0 ? 500 : 400 }} > */}
                    {vatAmount !== 0 ? (<table className="w-full border-t border-[#abaaaa]" style={{ minHeight: 380 }}>
                        <thead className=" ">
                            <tr className="border-b border-[#abaaaa] text-center">
                                <th style={{ width: "5px" }} className="border-r border-[#abaaaa] ">S.No</th>
                                <th className="border-r border-[#abaaaa] ">Description of Goods</th>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <th style={{ width: "70px" }} className="border-r border-[#abaaaa] ">HSN/SAC</th>
                                )}
                                {vatAmount !== 0 && <th style={{ width: "40px" }} className="border-r border-[#abaaaa] ">GST</th>}
                                <th style={{ width: "35px" }} className="border-r border-[#abaaaa] ">Qty</th>
                                <th style={{ width: "60px" }} className="border-r border-[#abaaaa] ">Rate</th>
                                <th style={{ width: "35px" }} className="border-r border-[#abaaaa] ">per</th>
                                <th style={{ width: "85px" }} className="border-b border-[#abaaaa] ">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {data &&
                                _.map(filteredData, (d, i) => (
                                    <tr className={`${i + 1 < _.size(filteredData) ? "h-2 " : ""}`}>
                                        <td style={{ width: "5px" }} className="border-r border-[#abaaaa] align-top text-center" >{i + 1}</td>
                                        <td className=" align-top text-left p-0.5 font-bold flex justify-between" >
                                            <div>
                                                <div>{d.product.name}</div>{invoiceData?.show_description_pdf_invoice === true && (
                                                    <div className="text-sm font-normal">{d.description}</div>)}
                                            </div>
                                            {/* {(data.frightCharges !== 0) &&
                                                <div className="mt-12 ">
                                                    (
                                                    <div className="text-right ">FRIGHT CHARGES</div>
                                                    )
                                                </div>
                                            } */}
                                        </td>
                                        {invoiceData?.show_Hsn_Code === true && (
                                            <td style={{ width: "70px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.hsn_code || ""}</td>
                                        )}
                                        {vatAmount !== 0 && <td style={{ width: "40px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5 text-center">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                        <td style={{ width: "35px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.productQuantity}</td>
                                        <td style={{ width: "60px" }} className="border-r border-[#abaaaa] align-top text-right p-0.5" >{DECIMAL_NUMBER(getProductSalePriceByGst(d), noOfDecimalDigits || 2)}</td>
                                        <td style={{ width: "35px" }} className="border-r border-[#abaaaa] align-top text-center p-0.5" >{d.product.unitType}</td>
                                        <td style={{ width: "85px" }} className="align-top font-bold">{DECIMAL_NUMBER((d.productQuantity * getProductSalePriceByGst(d)), noOfDecimalDigits || 2)}
                                            {/* <div className="mt-3 text-right border-t-2 border-dotted border-[#abaaaa] ">
                                                <div className="text-right "> {DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)} </div>
                                                {(data.frightCharges!==0) && (
                                                <div className="text-right mt-1"> {data?.frightCharges} </div>
                                                )}
                                           </div>  */}
                                        </td>
                                    </tr>
                                ))
                            }
                            {/* {data?.productQty < 5 && <div className="min-h-[150px]" />} */}

                            <tr className="h-6 border-b border-[#abaaaa]">
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right pr-2 font-bold align-bottom" >
                                    {vatAmount !== 0 &&
                                        <div>
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right ">CGST</div>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right ">SGST</div>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && <div className="text-right ">IGST</div>}
                                        </div>
                                    }
                                </td>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <td className="border-r border-[#abaaaa] " ></td>
                                )}
                                {vatAmount !== 0 && <td className="border-r border-[#abaaaa] " ></td>}
                                <td className="border-r border-[#abaaaa] text-center align-bottom" ></td>
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right" ></td>
                                <td className="text-right border-t border-[#abaaaa] " >
                                    <div className="font-bold">₹{DECIMAL_NUMBER((data.grandTotal - vatAmount || 0), noOfDecimalDigits || 2)}</div>
                                    {vatAmount !== 0 &&
                                        <div>
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right "> ₹{DECIMAL_NUMBER((vatAmount || 0) / 2, noOfDecimalDigits || 2)} </div>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right "> ₹{DECIMAL_NUMBER((vatAmount || 0) / 2, noOfDecimalDigits || 2)} </div>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && <div className="text-right "> ₹{DECIMAL_NUMBER(vatAmount, noOfDecimalDigits || 2)} </div>}
                                        </div>
                                    }
                                </td>
                            </tr>

                            <tr className="h-6 border-b border-[#abaaaa]">
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right pr-2 font-bold" >
                                    <div>Total</div>
                                </td>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <td className="border-r border-[#abaaaa] " ></td>
                                )}
                                {vatAmount !== 0 && <td className="border-r border-[#abaaaa] " ></td>}
                                <td style={{ fontSize: 15 }} className="border-r border-[#abaaaa] text-center align-bottom font-bold" >{data?.productQty}</td>
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right" ></td>
                                <td className="text-right border-t border-[#abaaaa] font-bold" >
                                    <div style={{ fontSize: 15 }}>₹{DECIMAL_NUMBER((data.grandTotal || 0), noOfDecimalDigits)}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>) : (<table className="w-full border-t border-[#abaaaa]" style={{ minHeight: 480 }}>
                        <thead className=" ">
                            <tr className="border-b border-[#abaaaa] text-center">
                                <th style={{ width: "5px" }} className="border-r border-[#abaaaa] ">S.No</th>
                                <th className="border-r border-[#abaaaa] ">Description of Goods</th>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <th style={{ width: "70px" }} className="border-r border-[#abaaaa] ">HSN/SAC</th>
                                )}
                                {vatAmount !== 0 && <th style={{ width: "40px" }} className="border-r border-[#abaaaa] ">GST</th>}
                                <th style={{ width: "35px" }} className="border-r border-[#abaaaa] ">Qty</th>
                                <th style={{ width: "60px" }} className="border-r border-[#abaaaa] ">Rate</th>
                                <th style={{ width: "35px" }} className="border-r border-[#abaaaa] ">per</th>
                                <th style={{ width: "85px" }} className="border-b border-[#abaaaa] ">Amount</th>
                            </tr>
                        </thead>
                        <tbody className="">
                            {data &&
                                _.map(filteredData, (d, i) => (
                                    <tr className={`${i + 1 < _.size(filteredData) ? "h-3 " : ""}`}>
                                        <td style={{ width: "5px" }} className="border-r border-[#abaaaa] align-top text-center" >{i + 1}</td>
                                        <td className=" align-top text-left p-0.5 font-bold flex justify-between" >
                                            <div>
                                                <div>{d.product.name}</div>{invoiceData?.show_description_pdf_invoice === true && (
                                                    <div className="text-sm font-normal">{d.description}</div>)}
                                            </div>
                                            {/* <div className="mt-12 ">
                                                {(data.frightCharges !== 0) && (
                                                    <div className="text-right ">FRIGHT CHARGES</div>
                                                )}
                                                {vatAmount !== 0 &&
                                                    <div>
                                                        {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right ">CGST</div>}
                                                        {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right ">SGST</div>}
                                                        {storeGSTCode !== customerGSTCode && customerGSTCode && <div className="text-right ">IGST</div>}
                                                    </div>
                                                }
                                            </div> */}
                                        </td>
                                        {invoiceData?.show_Hsn_Code === true && (
                                            <td style={{ width: "70px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.hsn_code}</td>
                                        )}
                                        {vatAmount !== 0 && <td style={{ width: "40px" }} className="border-l border-r border-[#abaaaa] align-top p-0.5 text-center">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                        <td style={{ width: "35px" }} className="border-l border-r border-[#abaaaa] align-top text-center p-0.5" >{d.productQuantity}</td>
                                        <td style={{ width: "60px" }} className="border-r border-[#abaaaa] align-top text-right p-0.5" >{d.productSalePrice}</td>
                                        <td style={{ width: "35px" }} className="border-r border-[#abaaaa] align-top text-center p-0.5" >{d.product.unitType}</td>
                                        <td style={{ width: "85px" }} className="align-top text-right font-bold">
                                            {DECIMAL_NUMBER(d.productQuantity * d.productSalePrice, noOfDecimalDigits)}
                                            {/* <div className="mt-3 text-right border-t-2 border-dotted border-[#abaaaa] ">
                                                <div className="text-right "> {DECIMAL_NUMBER((data.totalAmount || 0), noOfDecimalDigits)} </div>
                                                {(data.frightCharges!==0) && (
                                                <div className="text-right mt-1"> {data?.frightCharges} </div>
                                                )}
                                                {vatAmount !== 0 &&
                                                <div>
                                                   {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right "> {(DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits) / 2 )} </div> }
                                                   {(storeGSTCode === customerGSTCode || !customerGSTCode) && <div className="text-right "> {(DECIMAL_NUMBER((vatAmount || 0), noOfDecimalDigits) / 2 )} </div> }
                                                   {storeGSTCode !== customerGSTCode && customerGSTCode && <div className="text-right "> {DECIMAL_NUMBER(((((d.productSalePrice * getProductGst(d, true)) / 100) * d.productQuantity)), noOfDecimalDigits)} </div>}
                                                </div>
                                                 }
                                           </div>  */}
                                        </td>
                                    </tr>
                                ))
                            }
                            {/* {data?.productQty < 5 && <div className="min-h-[150px]" />} */}


                            <tr className="h-6 border-t border-b border-[#abaaaa]">
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right pr-2" > Total </td>
                                {invoiceData?.show_Hsn_Code === true && (
                                    <td className="border-r border-[#abaaaa] " ></td>
                                )}
                                {vatAmount !== 0 && <td className="border-r border-[#abaaaa] " ></td>}
                                <td style={{ fontSize: 15 }} className="border-r border-[#abaaaa] text-center font-bold" >{data?.productQty}</td>
                                <td className="border-r border-[#abaaaa] " ></td>
                                <td className="border-r border-[#abaaaa] text-right" ></td>
                                <td style={{ fontSize: 15 }} className="text-right font-bold" >₹{DECIMAL_NUMBER((data.grandTotal || 0), noOfDecimalDigits)}</td>
                            </tr>
                        </tbody>
                    </table>)}
                    <div className="text-left pl-1 mt-1">
                        <h4 className="">Total {isQuotation ? "Quotation" : "Invoice"} Amount Chargeable (in words)</h4>
                        <h4 className="font-bold pl-2">INR {totalInWords} Only</h4>
                    </div>

                    {vatAmount !== 0 &&
                        <table className="w-full border-t border-[#abaaaa]">
                            <thead className="">
                                <tr className="border-b border-[#abaaaa]">
                                    <th style={{ width: "150px" }} className="border-r border-[#abaaaa] text-center" colspan="4">{<p>GST%</p>}</th>
                                    {vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2">Taxable Value</th>}
                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2"> Central Tax
                                        <div className="flex justify-between  border-t border-[#abaaaa]">
                                            <div className="pl-4" colspan="1">Rate </div>
                                            <div className="pr-4" colspan="1">Amount</div>
                                        </div></th>}
                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="2">State Tax
                                        <div className="flex justify-between border-t border-[#abaaaa]">
                                            <div className="pl-4" colspan="1">Rate </div>
                                            <div className="pr-4" colspan="1">Amount</div>
                                        </div></th>}
                                    {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <th className="border-r border-[#abaaaa] text-center" colspan="4">IGST
                                        <div className="flex justify-between border-t border-[#abaaaa]">
                                            <div className="" colspan="2">Rate </div>
                                            <div className="" colspan="2">Amount</div>
                                        </div></th>}
                                    <th className="text-center" colspan="2" >Total
                                        <div className="">Tax Amount </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {data &&
                                    _.map(groupedGstData, (d) => (
                                        <tr className="border-b text-right">
                                            <td style={{ width: "150px" }} className="text-center pl-0.5 border-r border-[#abaaaa]" colspan="4"> <div>{d.productGST}</div> </td>
                                            {vatAmount !== 0 && <td className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER((d.taxableValue || 0), noOfDecimalDigits || 2)}</td>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{getProductGst(d) / 2}%</td>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((d.taxableValue * (getProductGst(d) / 100)) / 2, noOfDecimalDigits || 2)}</td>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits) / 2}%</td>}
                                            {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="1">{DECIMAL_NUMBER((d.taxableValue * (getProductGst(d) / 100)) / 2, noOfDecimalDigits || 2)}</td>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER((getProductGst(d)), noOfDecimalDigits)}%</td>}
                                            {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <td style={{ width: "75px" }} className=" border-r border-[#abaaaa]" colspan="2">{DECIMAL_NUMBER(((((d.taxableValue * getProductGst(d)) / 100))), noOfDecimalDigits || 2)}</td>}
                                            <td className=" " colspan="2">{DECIMAL_NUMBER(((((d.taxableValue * getProductGst(d)) / 100))), noOfDecimalDigits || 2)}</td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                            {vatAmount > 0 &&
                                <tr className="text-right">
                                    <th style={{ width: "150px" }} className=" border-r border-[#abaaaa] " colspan="4">Total</th>
                                    <th className="text-center border-r border-[#abaaaa]" colspan="2" >{ }</th>
                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="2">{(DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits || 2))}</th>}
                                    {(storeGSTCode === customerGSTCode || !customerGSTCode) && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="2">{(DECIMAL_NUMBER(vatAmount / 2, noOfDecimalDigits || 2))}</th>}
                                    {storeGSTCode !== customerGSTCode && customerGSTCode && vatAmount !== 0 && <th className=" border-r border-[#abaaaa]" colspan="4">{DECIMAL_NUMBER(vatAmount, noOfDecimalDigits || 2)}</th>}
                                    <td className="font-bold " colspan="2">{DECIMAL_NUMBER(vatAmount, noOfDecimalDigits || 2)}</td>
                                </tr>
                            }
                        </table>
                    }

                    <div className="border-t border-[#abaaaa] pl-1">
                        <div className="grid grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <h4 className="underline underline-offset-2 text-left">Terms and Conditions</h4>
                                {(invoiceData?.terms_conditions || data?.terms_conditions) &&
                                    <>
                                        <ol className="text-left text-sm list-inside">
                                            <li dangerouslySetInnerHTML={{ __html: data?.terms_conditions ? data?.terms_conditions : invoiceData?.terms_conditions }}></li>
                                        </ol>
                                    </>}
                            </div>
                            <div className="col-span-1">
                                {(invoiceData?.acc_holder_name || invoiceData?.acc_number || invoiceData?.acc_ifsc || invoiceData?.bank_name || invoiceData?.bank_branch) && <h4 className="text-left font-semibold">Company's Bank Details</h4>}
                                <div style={{ fontSize: "12px" }} className="grid grid-cols-10 text-left">
                                    <div className="col-span-4">
                                        {invoiceData?.bank_name && <div>Bank Name</div>}
                                        {invoiceData?.acc_number && <div> A/c No.</div>}
                                        {invoiceData?.acc_ifsc && <div>Branch & IFSC Code</div>}
                                    </div>
                                    <div className="col-span-6">
                                        {invoiceData?.bank_name && <div>: {invoiceData?.bank_name}</div>}
                                        {invoiceData?.acc_number && <div>: {invoiceData?.acc_number}</div>}
                                        {invoiceData?.bank_branch && <div className="flex">: {invoiceData?.bank_branch} <span>{invoiceData?.acc_ifsc && <div className="pl-0.5">& {invoiceData?.acc_ifsc}</div>}</span></div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-2 border-t border-[#abaaaa] text-center">
                        <div className="border-r border-[#abaaaa] font-semibold" >
                            <h4 className="mb-8 pl-1">Customer's Seal and Signature</h4>
                        </div>
                        <div className=" p-0.5 font-semibold " >
                            <h4 className="">For <span className="font-bold">{invoiceData?.companyName}</span></h4>
                            <div className="flex justify-center h-12">{invoiceData?.signatureSnapshot && <img className="h-[60px]" src={invoiceData?.signatureSnapshot} />}</div>
                            <h4 className="-mb-0.5">Authorised Signatory</h4>
                        </div>
                    </div>

                </div>

            </div>
        </Fragment>
    );
});

const SaleInvoiceV2A4 = ({ data, vatAmount, styles = {}, successCallBack, preCall, isQuotation = false, invoiceContainerRef }) => {
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => successCallBack?.(),
    });

    const [invoiceData, setInvoiceData] = useState(null);
    const [productSettings, setProductSettings] = useState(null);
    useEffect(() => {
        getSetting().then((data) => setInvoiceData(data.result));
        getProductSetting().then((data) => setProductSettings(data?.result));
    }, []);

    return (
        <div>
            {/* <div className='' style={{ position: 'absolute', left: 100, right: 0, top: 1000, background: 'white' }}> */}
            <div ref={invoiceContainerRef} style={{ position: "absolute", top: "-10000px" }} >
                <PrintToPdf
                    ref={componentRef}
                    data={data}
                    invoiceData={invoiceData}
                    vatAmount={vatAmount}
                    isQuotation={isQuotation}
                    productSettings={productSettings}
                />
            </div>
            {invoiceData && (
                <>
                    <Button style={styles} type='primary' shape='round' onClick={() => {
                        if (_.isFunction(preCall)) {
                            preCall(handlePrint);
                        } else {
                            handlePrint()
                        }
                    }}>
                        A4 Print4
                    </Button>
                </>
            )}
        </div>
    );
};

export default SaleInvoiceV2A4;
